const FontStyle = {
    BOLD: 'bold',
    ITALIC: 'italic',
    NORMAL: 'normal'
}

export const Label = ({ component }: { component: any }) => {
    const fontSize = component?.fontSize;
    let fontStyle = component?.fontStyle;
    if(Array.isArray(fontStyle)) 
        fontStyle = fontStyle[0];

    let styleClass = '';
    switch (fontStyle) {
        case FontStyle.BOLD:
            styleClass = 'font-bold';
            break;
        case FontStyle.ITALIC:
            styleClass = 'italic';
            break;
        case FontStyle.NORMAL:
            styleClass = 'not-italic';
            break;
        default:
            break;
    }

    if (component.text)
        return <span className={`${styleClass}`} style={{ ...component.ui_args, fontSize: `${fontSize}px` }}>{component.text}</span>;
    return <></>
}