import React, { Component } from "react";
import { connect } from "react-redux";
import { RootState } from "../../app/store";
import { Message } from "./Message";
import { AppContext } from "../../providers";
import { TwinAppEvent, ITwinTheme, IMessage } from "@twin-shared";
import DotLoading from "../../components/DotLoading";
import { debounce } from "../../utility";
import "./styles.css";

class MessageListComponent extends Component<
  {
    messageList: any[];
    mimiIsTyping: boolean;
    theme: ITwinTheme;
    isOnline: boolean;
  },
  { timerRunning: boolean }
> {
  //@ts-ignore
  context!: React.ContextType<typeof AppContext>;
  private _scrollViewRef: HTMLDivElement | null | undefined;
  private _nodes: Map<number, any> | null;
  static contextType = AppContext;

  constructor(props: {
    messageList: Array<IMessage>;
    mimiIsTyping: boolean;
    theme: ITwinTheme;
    isOnline: boolean;
  }) {
    super(props);
    this.state = {
      timerRunning: false,
    };
    this._nodes = new Map();
  }

  componentDidMount() {
    window.addEventListener(TwinAppEvent.onScrollToEnd, () => {
      this.debounceScrollToEndHanlder();
    });

    window.addEventListener(TwinAppEvent.onScrollToElement, () => {
      this.debounceScrollToElementHanlder(0);
    });
  }

  componentDidUpdate(): void {
    const { isChatBoxFocused } = this.context;
    if (isChatBoxFocused && this._scrollViewRef.scrollTop < -2) {
      this.debounceScrollToEndHanlder();
    }
  }

  debounceScrollToEndHanlder = debounce(() => {
    this._scrollViewRef?.scroll({
      top: 0,
      behavior: "smooth",
    });
  });

  debounceScrollToElementHanlder = debounce((lastIndex) => {
    this.scrollToElement(lastIndex);
  }, 800);

  componentWillUnmount(): void {
    this._nodes?.clear();
    window.removeEventListener(TwinAppEvent.onScrollToEnd, () => {});
    window.removeEventListener(TwinAppEvent.onScrollToElement, () => {});
  }

  scrollToElement = (indexOf: number) => {
    const node = this._nodes?.get(indexOf);
    if (node && node.offsetTop) {
      const height = node.clientHeight;
      this._scrollViewRef?.scroll({
        top: -height + this._scrollViewRef.clientHeight - 30,
        behavior: "smooth",
      });
    }
  };

  render(): React.ReactNode {
    const { messageList, mimiIsTyping, isOnline } = this.props;

    return (
      <div
        id="chat"
        data-testid="chat-id"
        ref={(ref) => {
          this._scrollViewRef = ref;
        }}
        className={`max-h-[90vh] flex flex-1 overflow-y-scroll flex-col-reverse mb-0 mt-auto lg:mx-HORIZONTAL_MARGIN md:mx-[10%] mx-[5%] fixed ${isOnline ? "md:bottom-[87px] bottom-[110px]" : "bottom-36"}   left-0 right-0`}
        style={{ scrollbarWidth: "none" }}
      >
        {mimiIsTyping && <DotLoading />}

        {messageList
          .slice()
          .reverse()
          .map((message, index) => (
            <Message
              message={message}
              key={`message-${index}`}
              index={index}
              setRef={(ref) => this._nodes?.set(index, ref)}
            />
          ))}
      </div>
    );
  }
}

const mapStateToProps = (state: RootState) => {
  return {
    messageList: state.message.list,
    mimiIsTyping: state.message.mimiIsTyping,
  };
};

export const MessageList = connect(mapStateToProps)(MessageListComponent);
